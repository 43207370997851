.popover {
  :global(.ant-popover-inner) {
    box-shadow: 0 0 0.5px rgba(0, 0, 0, 0.4), 0 32px 64px rgba(113, 121, 128, 0.32);
  }
}

.accountLink {
  &:global(.ant-popover-open) {
    .dropdownArrow {
      transform: rotate(-180deg);
    }
  }

  &:hover,
  &:focus {
    color: var(--theme-yellow-color);
  }
}

.value {
  font-size: 18px;
  font-weight: 500;

  grid-column-end: none;
}

.statusTag {
  color: var(--theme-lilac-color);

  background: var(--theme-tag-background-color);

  border-radius: 12px;

  padding: 6px 12px;
}

.icon {
  color: var(--theme-default-color);
}

.button {
  height: 46px;
  border-radius: 12px;
}

.ghostButton {
  color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);
}

.ghostButton:hover {
  color: var(--theme-lilac-color);
}

.walletAddress:hover,
.walletAddress:focus {
  color: var(--theme-yellow-color);
}

.dropdownArrow:hover,
.dropdownArrow:focus {
  color: var(--theme-yellow-color);
}

@media (max-width: 768px) {
  .walletAddress,
  .dropdownArrow {
    display: none;
  }
}
