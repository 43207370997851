.button {
  text-transform: uppercase;
  font-weight: bold;
}

.secondary {
  color: var(--theme-white-color) !important;
  background-color: var(--theme-lilac-background-color-2) !important;
}

.primary {
  color: var(--theme-yellow-color);
  background-color: var(--theme-primary-color);
}

.ghost {
  border: 1px solid var(--theme-lilac-color) !important;
  color: var(--theme-white-color) !important;
}

.link {
  > span {
    text-decoration: underline;
  }
}

:global {
  .ant-btn-ghost:hover {
    color: var(--theme-yellow-color) !important;
  }

  .ant-btn-link:hover {
    color: var(--theme-yellow-color) !important;
  }

  .ant-btn-lg {
    height: 55px;
  }
}
