.accounts {
  overflow: hidden;
  overflow-y: auto;
  max-height: 400px;
}

.accountItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;

  border-bottom: 1px solid var(--theme-primary-color);

  padding: 16px;
}

.accountSelected {
  background-color: var(--theme-lilac-tint3-background-color);
}

.invisible {
  visibility: hidden;
}

.selectedText {
  font-weight: 700;
}

.action {
  padding: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.actionBtn {
  width: 207px;
}

:global {
  .ant-modal-header {
    background-color: var(--theme-body-color) !important;
    border-bottom-color: var(--theme-primary-color) !important;
  }

  .ant-modal-body {
    padding: 0 !important;
    background-color: var(--theme-body-color) !important;
  }
}
