@import 'styles/helpers';

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  padding: 0 80px 120px;

  @include d {
    padding: 0 40px;
  }
  @include t {
    padding: 0 20px;
  }
  @include m {
    padding: 0 24px;
  }
}

.inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
