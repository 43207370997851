.popover {
  margin-top: 20px;

  :global(.ant-popover-inner) {
    box-shadow: 0 0 0.5px rgba(0, 0, 0, 0.4), 0 32px 64px rgba(113, 121, 128, 0.32);
  }
}

.content {
  background-color: var(--theme-primary-color) !important;
  padding: 10px 50px;
}

.accountLink {
  margin-bottom: 30px;
}

.statusTag {
  background: var(--theme-tag-background-color);
  border-radius: 12px;
  padding: 4px 12px;
}

.icon {
  color: var(--theme-default-color);
}

.navItem {
  cursor: pointer;

  &:hover {
    color: var(--theme-secondary-color);
  }
}

@media (max-width: 768px) {
  .walletAddress,
  .dropdownArrow {
    display: none;
  }
}

.listItem {
  justify-content: flex-end;
  padding: 8px 0;
}
