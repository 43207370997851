@import 'styles/helpers';

.profile {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.head {
  padding: 20px 0;
  border-bottom: 1px solid #f1f2f4;

  @include m {
    padding: 16px 0 32px;
  }
  @include dark {
    border-color: var(--theme-primary-color);
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include d {
      padding: 0 40px;
    }
    @include m {
      display: block;
      padding: 0 32px;
    }

    h2 {
      margin-bottom: 0;
    }
  }
}

.title {
  margin-right: auto;
  @include d {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -0.01em;
  }
  @include t {
    font-size: 32px;
    line-height: 40px;
  }
}

.breadcrumbs {
  margin-left: 40px;
  @include m {
    margin: 16px 0 0;
  }
}

.body {
  flex-grow: 1;
  padding: 20px 0;

  // background: #F8F8F9;(update layout wording styles for start verification page)
  @include d {
    padding-top: 20px;
  }
  @include t {
    padding-top: 10px;
  }
  @include m {
    padding: 10px 0;
  }
  @include dark {
    background: none;
  }

  .container {
    display: flex;
    align-items: flex-start;
    @include d {
      padding: 0 40px;
    }
    @include t {
      display: block;
    }
    @include m {
      padding: 0 16px;
    }
  }
}

.sidebar {
  flex-shrink: 0;
  width: 200px;
  margin-right: 10px;
  @include x {
    margin-right: 8px;
  }
  @include d {
    width: 160px;
    margin-right: 4px;
  }
  @include t {
    width: 100%;
    margin-bottom: 32px;
  }
  @include m {
    margin-bottom: 24px;
  }
}

.wrapper {
  flex-grow: 1;
  @include dark {
    // background: #18191D;
  }
}

.dropdown {
  @include t {
    position: relative;
    z-index: 5;

    &.active {
      .top {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      .group {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.top {
  display: none;
  @include t {
    @include button-2;
    position: relative;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 64px 0 16px;
    border-radius: 24px;
    background: var(--theme-box-background-color);
    // @include dark {
    //   background: var(--theme-primary-color);
    // }
    svg {
      fill: var(--theme-primary-color);
      margin-right: 8px;
      @include dark {
        fill: var(--theme-primary-color);
      }
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 16px;
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M16.207 9.793a1 1 0 0 0-1.414 0L12 12.586 9.207 9.793a1 1 0 0 0-1.414 1.414l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E")
        no-repeat 50% 50% / 100% auto;
      transition: transform 0.2s;
    }
  }

  svg {
    margin-right: 8px;
  }
}

.group {
  padding: 14px;

  @include t {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    z-index: 2;
    padding: 16px;
    background: var(--theme-box-background-color);
    border-radius: 24px;
    box-shadow: 0 40px 32px -24px rgba(15, 15, 15, 0.12);
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s;
    @include dark {
      background: var(--theme-primary-color);
    }
  }
}
