.image {
  // width: 100%;
  min-height: 100%;
  object-fit: cover;
  vertical-align: bottom;
  pointer-events: none;
}

.container {
  // max-width: 100%;
  // pointer-events: none;
  // z-index: -1;
  background-color: var(--theme-lilac-color);
  min-height: 100vh;
  width: 100%;
  // padding: 66px;
  // @include d {
  //   padding-top: 33px;
  // }
  // @include m {
  //   padding-top: 22px;
  // }
  // overflow: hidden;
  // position:absolute;

  background-image: url('../../resources/svg/bgpattern.svg');
}
