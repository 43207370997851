@import 'styles/helpers';

.logo {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 100px;
    height: 100px;
    @include m {
      width: 68px;
      height: 68px;
    }
  }

  .text {
    @include m {
      display: none;
    }
  }
}
