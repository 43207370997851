@import 'src/styles/antd/text';

.button-primary,
.button-secondary,
.button-ghost,
.button-ghost-alt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 11px 32px;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  white-space: nowrap;

  border: 1px solid transparent;

  &:disabled,
  &[disabled],
  &.disabled {
    background: rgba(170, 175, 179, 0.08);
    border: 1px solid rgba(170, 175, 179, 0.08);
    box-shadow: none;
    color: #aaafb3;
    cursor: not-allowed;
    pointer-events: none;
  }
}

.button-primary {
  background: #009bdf;
  box-shadow: 0 8px 16px rgba(0, 155, 223, 0.16);
  border-radius: 4px;
  color: #fff;

  &:hover {
    background: #61cdff;
    color: #fff;
    box-shadow: 0 8px 16px rgba(97, 205, 255, 0.24);
  }

  &:active {
    background: #006ead;
    box-shadow: 0 4px 8px rgba(0, 110, 173, 0.16);
    color: #fff;
  }
}

.button-secondary {
  background: rgba(0, 155, 223, 0.1);
  border-radius: 4px;
  color: #009bdf;

  &:hover {
    background: rgba(97, 205, 255, 0.1);
    color: #61cdff;
  }

  &:active {
    background: rgba(0, 110, 173, 0.08);
    color: #006ead;
  }
}

.button-ghost {
  border-color: #009bdf;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;
  color: #009bdf;

  &:hover {
    background: rgba(97, 205, 255, 0.1);
    border: 1px solid #51c4f1;
    color: #61cdff;
  }

  &:active {
    background: rgba(0, 110, 173, 0.04);
    border: 1px solid #006ead;
    color: #006ead;
  }
}

.button-ghost-alt {
  border: 1px solid #e3e4e6;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 4px;

  color: #aaafb3;

  &:hover {
    background: rgba(97, 205, 255, 0.1);
    border: 1px solid #51c4f1;
    color: #61cdff;
  }

  &:active {
    background: rgba(0, 110, 173, 0.04);
    border: 1px solid #006ead;
    color: #006ead;
  }
}

.button-small {
  font-size: 12px;
  line-height: 16px;
  padding: 9px 24px;
}

.button-big {
  font-size: 16px;
  line-height: 24px;
  padding: 19px 48px;
}

.button-icon {
  padding: 11px;

  &.button-big {
    padding: 19px;
  }
}

.button-text {
  @include font-p1-regular;
  color: var(--theme-secondary-color);
  border: 0;
  background-color: transparent;
  padding: 0;

  display: inline-flex;
  align-items: center;

  &:hover {
    color: var(--theme-primary-color);
  }

  &:active {
    color: var(--theme-primary-color);
  }
}

.button-ghost-monochrome {
  background: transparent;
  border: 1px solid var(--theme-border-color);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 12px 32px;
  color: var(--theme-secondary-color);
  height: 48px;

  &:hover {
    color: var(--theme-primary-color);
    border: 1px solid #009bdf;
  }

  &:active {
    color: var(--theme-primary-color);
  }

  &.selected {
    border-color: var(--theme-primary-blue-color);
  }
}

.button-back {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  color: var(--theme-secondary-color);
  border: 0;
  background-color: transparent;
  padding: 12px 0;

  display: flex;
  align-items: center;

  &:hover {
    color: var(--theme-primary-color);
  }

  &:active {
    color: var(--theme-primary-color);
  }
}

.link-blue {
  color: var(--theme-blue-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  background-color: transparent;
  border: 0;
  padding: 0;

  &:hover,
  &:active {
    color: var(--theme-blue400-color);
  }

  [data-theme='dark'] & {
    color: var(--theme-blue400-color);

    &:hover,
    &:active {
      color: var(--theme-blue-color);
    }
  }
}

.token-select {
  border: 1px solid var(--theme-border-color);
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 24px;
  width: 100%;
  background-color: transparent;
}

.token-select-chevron {
  color: var(--theme-icon-color);

  button:hover & {
    color: var(--theme-icon-hover-color);
  }
}
