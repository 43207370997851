.layout {
  display: flex;
  height: 100vh;

  :global(.ant-layout-footer) {
    background: var(--theme-primary-color);
    color: var(--theme-white-color);
    padding: 100px 0;
  }
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;

  width: 100vw;
  position: relative;
  overflow: hidden;
  min-height: 600px;
}

.title {
  width: 50%;
}

.icon {
  position: absolute;
  right: 15%;
  bottom: -200px;

  > img {
    height: 700px;
  }
}

.link {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
}
