@import 'styles/helpers';

.header {
  position: relative;
  background-color: var(--theme-primary-color);
  color: var(--theme-yellow-color);
  z-index: 10;
  padding: 0 56px;
  @include m {
    padding: 32px 0 24px;
  }

  &.wide {
    box-shadow: none;

    .container {
      max-width: 100%;
      padding: 0 20px;
      @include m {
        padding: 0 24px;
      }
    }
  }
}

.container {
  display: flex;
  align-items: center;
}

.logo {
  margin-right: 32px;
  @include t {
    margin-right: 16px;
  }
  @include m {
    position: relative;
    z-index: 15;
    margin-right: auto;
  }

  img {
    width: 136px;
    @include t {
      width: 42px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 2rem;
  @include x {
    padding-left: 0;
    border: unset;
  }
  @include m {
    flex-grow: 0;
  }

  .theme {
    @include d {
      display: none;
    }
  }

  > .btns {
    @include m {
      display: none;
    }
  }
}

.control {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  .button {
    @include m {
      display: none;
    }
  }
}

.settings {
  margin-right: 32px;
  @include d {
    margin-right: 16px;
  }
  @include t {
    margin-right: 4px;
  }
}

.notifications,
.control .button,
.theme {
  margin-right: 20px;
  @include d {
    margin-right: 16px;
  }
  @include t {
    margin-right: 8px;
  }
  @include m {
    margin-right: 12px;
  }
}

.btns {
  display: flex;
  @include m {
    margin: auto 16px 0;
  }

  .button {
    @include m {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin: 0 8px;
    }

    &:not(:last-child) {
      margin-right: 8px;
      @include m {
        margin-right: 8px;
      }
    }
  }
}
