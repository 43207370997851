.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: var(--theme-primary-color);

  color: var(--theme-white-color);

  padding: 100px 0;
}

.text {
  color: var(--theme-white-color);
  text-align: center;
}

.icons {
  margin-top: 60px;
}

.links {
  margin-top: 117px;
}

.link:hover {
  color: var(--theme-secondary-color);
}
