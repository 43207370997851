$gaps: 4, 8, 12, 16, 24, 32, 48, 64;
$align-items: start, center, end;
$justify-contents: start, center, end, space-between;

.grid {
  display: grid;

  &.row {
    grid-auto-flow: row;
    grid-template-rows: repeat(auto-fit, minmax(0, max-content));

    @each $align in $align-items {
      &.align-#{$align} {
        justify-items: #{$align};
      }

      &.align-self-#{$align} {
        justify-self: #{$align};
      }
    }

    @each $justify in $justify-contents {
      &.justify-#{$justify} {
        align-content: #{$justify};
      }

      &.justify-self-#{$justify} {
        align-self: #{$justify};
      }
    }
  }

  &.col {
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fit, minmax(0, max-content));

    @each $align in $align-items {
      &.align-#{$align} {
        align-items: #{$align};
      }

      &.align-self-#{$align} {
        align-self: #{$align};
      }
    }

    @each $justify in $justify-contents {
      &.justify-#{$justify} {
        justify-content: #{$justify};
      }

      &.justify-self-#{$justify} {
        justify-self: #{$justify};
      }
    }
  }

  @each $gap in $gaps {
    &.row-gap-#{$gap} {
      grid-row-gap: #{$gap}px;
    }

    &.col-gap-#{$gap} {
      grid-column-gap: #{$gap}px;
    }
  }
}
