.sign_up_disabled_modal {
  .content {
    padding: 96px 56px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .btn {
    height: 48px;
  }

  .warning_btn {
    background-color: var(--theme-red-color) !important;
  }
}
