.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 100vh;
  width: 100vw;

  position: relative;
}

.icon {
  position: absolute;
  left: calc(50% - 147px);
  bottom: -80px;
}
