.placeholder {
  background-color: var(--theme-white-color);
  text-align: center;
  border: 1px solid #a8a8a8;
}

:global(.ant-layout-footer) {
  background: var(--theme-primary-color);
  color: var(--theme-white-color);
  padding: 100px 0;
}
