@import 'src/styles/antd/text';

.overlay:global(.ant-popover) {
  :global(.ant-popover-inner) {
    background: var(--theme-overlay-color);
  }

  :global(.ant-popover-arrow) {
    border-color: var(--theme-overlay-color);
  }

  :global(.ant-popover-title) {
    @include font-p1-regular;
    padding: 24px;
    border-bottom: 1px solid var(--theme-border-color);
    color: var(--theme-primary-color);
  }

  :global(.ant-popover-inner-content) {
    padding: 24px;
  }

  &.noPadding {
    :global(.ant-popover-inner-content) {
      padding: 0;
    }
  }
}
