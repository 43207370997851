.heading_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.714rem;
  width: 100%;
  max-width:700px;
}

.heading_btn {
  width: 100px;
}

.heading_back {
  display: flex;
  flex-direction: row;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  h3 {
    margin: unset;
    line-height: 1;
    margin-top: 0.25rem;
  }
  svg {
    margin: unset;
    margin-right: 0.5rem;
  }
}

.heading_back_icon {
  margin-bottom: 0.5em;
  margin-right: 0.5em;
  line-height: 1.35;
}

.heading_back_label {
  text-transform: uppercase;
}

.heading_title {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0 !important;
}
