.component {
  width: 853px !important;

  :global(.ant-modal-content) {
    box-shadow: 0 16px 32px rgba(19, 32, 43, 0.4), 0 0 0.5px rgba(6, 10, 13, 0.4);
  }

  :global(.ant-modal-close) {
    top: 65px;
    right: 65px;
  }

  :global(.ant-modal-close-x) {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  :global(.ant-modal-close-x) svg {
    color: var(--theme-icon-color);
  }

  :global(.ant-modal-header) {
    padding: 65px;
    background: var(--theme-card-color);
    border-bottom: 1px solid var(--theme-border-color);

    :global(.ant-modal-title) {
      color: var(--theme-primary-color);
      font-size: 40px;
      line-height: 54px;
      font-weight: 700;
    }
  }

  :global(.ant-modal-body) {
    background: var(--theme-card-color);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow-y: auto;
  }
}

.newModal {
  width: 800px !important;

  .title {
    font-size: 32px !important;
    font-weight: 700 !important;
    color: var(--theme-white-color);

    margin-top: 32px;
  }

  .description {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: var(--theme-white-color);
    text-align: center;

    margin-top: 32px;
  }

  .comment {
    font-size: 20px !important;
    font-weight: 700 !important;
    color: var(--theme-white-color);
    text-align: center;

    margin-top: 16px;
  }

  .note {
    font-size: 20px !important;
    font-weight: 400 !important;
    color: var(--theme-white-color);
    text-align: center;

    margin-top: 32px;
  }

  .button {
    margin-top: 32px;

    width: 375px;
  }

  :global(.ant-modal-content) {
    background-color: transparent !important;
  }

  :global(.ant-modal-body) {
    background-color: var(--theme-primary-color) !important;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 32px 64px !important;
  }

  :global(.ant-modal-footer) {
    display: none;
  }

  :global(.ant-modal-content) {
    box-shadow: 0 16px 32px rgba(19, 32, 43, 0.4), 0 0 0.5px rgba(6, 10, 13, 0.4);
  }

  :global(.ant-modal-close-x) {
    position: absolute;
    top: 21px !important;
    right: 18px !important;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  :global(.ant-modal-close-x) svg {
    color: var(--theme-icon-color);
  }
}

.confirm_modal {
  background: #ebebeb;
  border-radius: 8px;

  min-width: 700px;

  .confirm_modal_content {
    padding: 0;
  }

  :global(.ant-modal-content) {
    background-color: transparent !important;
  }

  :global(.ant-modal-body) {
    background-color: ebebeb !important;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 32px 64px !important;
  }

  :global(.ant-modal-footer) {
    display: none;
  }

  :global(.ant-modal-content) {
    box-shadow: 0 16px 32px rgba(19, 32, 43, 0.4), 0 0 0.5px rgba(6, 10, 13, 0.4);
  }

  :global(.ant-modal-close-x) {
    position: absolute;
    top: 21px !important;
    right: 18px !important;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  :global(.ant-modal-close-x) svg {
    color: var(--theme-icon-color);
  }
}

:global(.ant-modal) {
  padding-bottom: 0 !important;
}
