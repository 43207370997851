@import 'styles/helpers';

.container {
  width: 360px;
  padding: 20px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.wide {
  display: flex;
  justify-content: center;
  width: 700px;
  @include m {
    width: 400px;
  }
}

.item {
  height: 56px;
  border-radius: 14px;
}

.button {
  height: 46px;
  min-width: 220px;
  border-radius: 14px;
}

.logo {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 100px;
    height: 80px;

    @include m {
      width: 68px;
      height: 50px;
    }
  }

  .text {
    @include m {
      display: none;
    }
  }
}

.title {
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 63px;
  /* identical to box height */
  letter-spacing: 0.03em;
}

.desc {
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */
  text-align: center;
  letter-spacing: 0.03em;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.bottom {
  width: '100%';
  color: var(--theme-primary-color);
  font-family: 'Roc Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.03em;
}

.link {
  margin-left: 0.5rem;
  color: var(--theme-lilac-color);
  font-family: 'Roc Grotesk';
}

.password-character-types {
  padding-left: 1.25rem;
}

.password-rule-item {
  display: flex;
  align-items: center;
  padding: 0.25rem;

  span {
    margin-right: 0.5rem;
  }
}

.password-rule-item-dot {
  width: 3px;
  height: 3px;
  background: var(--theme-black-color);
  border-radius: 50%;
}

.password-rule-item-icon {
  width: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rule-success {
  color: var(--theme-green-color);

  span {
    color: var(--theme-green-color);
  }
}

:global {
  .ant-input::placeholder {
    color: var(--theme-primary-color);
    font-family: 'Roc Grotesk';
    line-height: 82px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 23px;
    /* identical to box height */
    letter-spacing: 0.03em;
    opacity: 0.5;
  }
}
