.nav {
  display: flex;
  flex-direction: row;
  align-items: center;

  h5 {
    margin-top: 0 !important;
  }
}

.nav_account {
  margin-right: 16px;
}

.active {
  color: var(--theme-red-color);
}

.btn,
.btn:hover,
.btn:active {
  height: auto;
  font-family: 'Tourney Bold';
  font-size: 22px;
}

.themed_btn,
.themed_btn:hover,
.themed_btn:active {
  background-color: var(--theme-primary-color);
  color: var(--theme-yellow-color);
  border-radius: 30px;
  padding: 0 20px;
  border-style: solid;
  border-color: var(--theme-yellow-color);
  border-width: 1px;

  :hover {
    color: var(--theme-white-color);
  }
}
